import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import {
  fetchCustomPlanList,
  getEmirates,
  getModel,
} from "../../redux/actions/apiActions";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { fetchCars } from "../../redux/actions/profileAction";
// import DatePicker from "react-date-picker/dist/entry.nostyle";
// import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import {
  getCarBrandModal,
  setLoginPopup,
  toggleLoginForm,
} from "../../redux/actions/globalAction";
import API from "api";

function CustomPlan({
  show,
  handleClose,
  apiData,
  getModel,
  getEmirates,
  fetchCars,
  globalData,
  getCarBrandModal,
  loginData,
  setLoginPopup,
  fetchCustomPlanList,
  toggleLoginForm,
}) {
  const [addState, setAddState] = useState("brand");
  const [formType, setFormType] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [brand, setbrand] = useState({});
  const [model, setModel] = useState({});
  const [infoForm, setInfoForm] = useState({});

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (show) {
      getEmirates();
      fetchCustomPlanList();
    }
  }, [show]);

  useEffect(() => {
    if (globalData.carData?.brand) {
      setAddState(false);
      setbrand({
        Name: globalData.carData?.brand,
        ImgUrl: globalData.carData?.brandUrl,
      });
      setModel({
        Name: globalData.carData?.modal,
        ImgUrl: globalData.carData?.modalUrl,
        ID: globalData.carData?.modelId,
      });
    }
  }, [globalData.carData]);

  useEffect(() => {
    setBrandList(apiData.brands);
    return () => {
      localStorage.removeItem("customPlan");
    };
  }, [apiData.brands]);

  useEffect(() => {
    setModelList(apiData.models);
  }, [apiData.models]);

  const searchBrand = (e) => {
    let text = String(e.target.value).toLowerCase();
    if (text.length == 0) {
      setBrandList(apiData.brands);
    } else {
      const filteredBrands = apiData.brands.filter((e) => {
        return e.Name.toLowerCase().includes(text);
      });
      setBrandList(filteredBrands);
    }
  };

  const searchModel = (e) => {
    let text = String(e.target.value).toLowerCase();
    if (text.length == 0) {
      setModelList(apiData.models);
    } else {
      const filteredModels = apiData.models.filter((e) => {
        return e.Name.toLowerCase().includes(text);
      });
      setModelList(filteredModels);
    }
  };

  const onBrandSelect = (selectedBrand) => {
    setbrand(selectedBrand);
    setAddState("model");
    getModel(selectedBrand.ID);
  };

  const onModelSelect = (selectedModel) => {
    localStorage.setItem("customPlan", true);
    setModel(selectedModel);
    setAddState("form");
    getCarBrandModal(
      brand.Name,
      selectedModel.Name,
      brand.ImgUrl,
      selectedModel.ImgUrl,
      brand.ID,
      selectedModel.ID,
      selectedModel.Slug
    );
    // getModel(selectedModel.ID)
  };

  const onCustomPlan = async (data) => {

    data.CarModelId = model.ID;
    data.EmirateId = data.EmirateId.value;
    let items = [];
    for (let i = 0; i < apiData.customPlanList.length; i++) {
      if (data.checkItem[i].item) {
        items.push(apiData.customPlanList[i].Id);
      }
    }
    data.CheckItems = items;
    if (!items.length) {
      toast.error("Please select at least one service!");
    }
    else {
      // let token = await JSON.parse(localStorage.getItem("login_access"));
      await API.post(`v2/api/packages/custom`, data, {
        headers: {
          // Authorization: "bearer " + token.access_token,
        },
      })
        .then(async (res) => {
          toast.dismiss();
          toast.success("Custom Plan Requested!");
          handleClose();
        })
        .catch((err) => {
          console.log("Error ", err);
          toast.dismiss();
          toast.error("Something went wrong! Please retry.");
        });
    }
  };

  const onInfoForm = (data) => {
    setInfoForm(data);
    setFormType("services");
  };

  const uploadMulkia = async (id) => {

    let token = await JSON.parse(localStorage.getItem("login_access"));
    let customerId = await JSON.parse(localStorage.getItem("customerId"));
    let data = {};
    data.File = acceptedFiles[0];
    await API.post(`v2/api/customers/${customerId}/vehicles/${id}/mulkia`, data, {
      headers: {
        Authorization: "bearer " + token.access_token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async (res) => {
        handleClose();
        toast.dismiss();
        toast.success("Mulkia Added");
      })
      .catch((err) => {
        console.log("Error ", err);
        toast.dismiss();
        toast.error("Something went wrong! Please retry.");
      });
  };

  const emirateList = apiData.emirates.map((emirate) => {
    return {
      label: emirate.Name,
      value: emirate.ID,
    };
  });

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        {addState === "brand" ? (
          <Container>
            <Row>
              <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                <h3>Choose Car</h3>
                <p
                  role="button"
                  className="shadow p-2 bg-white rounded-circle"
                  onClick={handleClose}
                  style={{ background: "#F5F5F5" }}
                >
                  <img src="/assets/close.svg" alt="close" />
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center shadow w-100 mb-3 p-2">
                <a>
                  <img
                    src="/assets/search.svg"
                    width={35}
                    className={"pl-2"}
                    alt="search"
                  />
                </a>
                <input
                  type="text"
                  placeholder="Search Brand"
                  onChange={searchBrand}
                  style={{
                    width: "90%",
                    height: 40,
                    border: "none",
                    marginLeft: "5%",
                  }}
                />
              </div>
            </Row>
            <Row>
              {brandList?.map((e) => (
                <Col
                  xs={3}
                  role="button"
                  className="d-flex justify-content-between align-items-center mt-4"
                  onClick={() => onBrandSelect(e)}
                >
                  <img
                    src={e.ImgUrl}
                    className={" w-100 p-3 shadow rounded-circle brand-item"}
                    alt="brand"
                  />
                </Col>
              ))}
            </Row>
          </Container>
        ) : (
          <>
            {addState === "model" ? (
              <>
                <Container>
                  <Row>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-1">
                      <h3>Select Model</h3>
                      <p
                        role="button"
                        className="shadow p-2 bg-white rounded-circle"
                        onClick={handleClose}
                        style={{ background: "#F5F5F5" }}
                      >
                        <img alt="ob-image" src="/assets/close.svg" />
                      </p>
                    </div>
                  </Row>
                </Container>
                <Container fluid>
                  <Row className="my-3 align-items-center">
                    <img
                      alt="ob-image"
                      src="/assets/arrow-left.svg"
                      role="button"
                      width={25}
                      className={"pl-2"}
                      onClick={() => {
                        setAddState("brand");
                        setbrand({});
                      }}
                    />
                    <img
                      src={brand.ImgUrl}
                      className="p-2"
                      height="50px"
                      alt="brand"
                    />
                    <h5
                      className="m-0 font-weight-bold"
                      style={{ color: "#0451be", fontSize: "14px" }}
                    >
                      {brand.Name}
                    </h5>
                  </Row>
                </Container>
                <Container>
                  <Row>
                    <div className="d-flex justify-content-between align-items-center shadow w-100 mb-3 p-2">
                      <a>
                        <img
                          alt="ob-image"
                          src="/assets/search.svg"
                          width={35}
                          className={"pl-2"}
                        />
                      </a>
                      <input
                        type="text"
                        placeholder="Search Model"
                        onChange={searchModel}
                        style={{
                          width: "90%",
                          height: 40,
                          border: "none",
                          marginLeft: "5%",
                        }}
                      />
                    </div>
                  </Row>

                  <Row>
                    {modelList?.length === 0 && (
                      <>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
                          return (
                            <Col xs={4} role="button" className="mt-4">
                              <Skeleton height={80} />
                              <Skeleton count={1} />
                            </Col>
                          );
                        })}
                      </>
                    )}
                    {modelList &&
                      modelList.map((e) => (
                        <Col
                          xs={4}
                          role="button"
                          className="mt-4"
                          onClick={() => onModelSelect(e)}
                        >
                          <img src={e.ImgUrl} className={" "} alt="brand" />
                          <p
                            style={{
                              fontSize: "12px",
                              color: "#000",
                            }}
                            className="text-center"
                          >
                            {e.Name}
                          </p>
                        </Col>
                      ))}
                  </Row>
                </Container>
              </>
            ) : (
              <>
                {formType === "services" ? (
                  <>
                    <Container>
                      <Row>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-1">
                          <h3>Create a Custom Plan</h3>
                          <p
                            role="button"
                            className="shadow p-2 bg-white rounded-circle"
                            onClick={handleClose}
                            style={{ background: "#F5F5F5" }}
                          >
                            <img alt="ob-image" src="/assets/close.svg" />
                          </p>
                        </div>
                      </Row>
                    </Container>
                    <Container fluid>
                      <Row className="mt-3 align-items-center">
                        <img
                          src={brand.ImgUrl}
                          className="p-2"
                          height="50px"
                          alt="brand"
                        />
                        <h5
                          className="m-0 font-weight-bold"
                          style={{ color: "#0451be", fontSize: "14px" }}
                        >
                          {brand.Name}{" "}
                          <span style={{ fontWeight: 300 }}>
                            - {model.Name}
                          </span>
                        </h5>
                      </Row>
                      {/* <Row className="d-flex justify-content-center">
                    <img
                      src={
                         model.ImgUrl
                      }
                      className="w-50"
                    />
                  </Row> */}
                    </Container>
                    <Container>
                      <form onSubmit={handleSubmit(onCustomPlan)}>
                        <Row>
                          <Col lg={7} className="p-2">

                            <input
                              type="text"
                              className="common-input-box"
                              placeholder="Chassis Number"
                              {...register("ChassisNo", { required: false })}
                            />
                            {errors.ChassisNo && (
                              <span className="text-danger">
                                Chassis number is required
                              </span>
                            )}
                            <small style={{ fontSize: 12 }}>(Printed at the back of Registration Card)</small>
                          </Col>

                          <Col lg={5} className="p-2">
                            <input
                              type="number"
                              className="common-input-box"
                              placeholder="1963"
                              {...register("ManufacturingYear", {
                                required: true,
                                min: 1600,
                                max: new Date().getFullYear(),
                                maxLength: 4,
                              })}
                            />
                            {errors.ManufacturingYear && (
                              <span className="text-danger auth-validation-mft-span">
                                Enter Valid Year
                              </span>
                            )}
                          </Col>
                        </Row>
                        <h4 className="mt-3">
                          What services are you looking for?
                        </h4>
                        <Row>
                          {apiData.customPlanList?.map((item, index) => (
                            <Col md={6} className="mb-2">
                              <div className="custom-control custom-checkbox ">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={"customCheck" + index}
                                  {...register(`checkItem.${index}.item`)}
                                />
                                <label
                                  className="custom-control-label d-flex align-items-center"
                                  htmlFor={"customCheck" + index}
                                  style={{
                                    fontSize: 15,
                                  }}
                                >
                                  {item.Name}
                                </label>
                                {/* <small>Description Here</small> */}
                              </div>
                            </Col>
                          ))}

                          {/* <Col md={6}>
                            <div className="custom-control custom-checkbox ">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck2"
                                {...register("checkItem2")}
                              />
                              <label
                                className="custom-control-label d-flex align-items-center"
                                htmlFor="customCheck2"
                              >
                                Title
                              </label>
                              <small>Description Here</small>
                            </div>
                          </Col> */}
                        </Row>
                        <Row className="mt-3 justify-content-center" style={{ padding: "10px " }}>
                          <Button
                            type="submit"
                            variant=""
                            size="lg"
                            className="text-black btn-profile-update"
                            disabled={isSubmitting}
                            style={{
                              fontSize: "20px",
                              background: "linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%)",
                              border: "none",
                              height: "47px",
                              width: "90%",
                              fontWeight: "700",
                              borderRadius: "50px"
                            }}
                          >
                            Submit
                            {isSubmitting && (
                              <Spinner
                                animation="grow"
                                role="status"
                                className="ml-2 mb-2"
                                size="sm"
                              >
                                <span className="visually-hidden"></span>
                              </Spinner>
                            )}
                          </Button>
                        </Row>
                      </form>
                    </Container>
                  </>
                ) : (
                  <>
                    <Container>
                      <Row>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-1">
                          <h3>Create a Custom Plan</h3>
                          <p
                            role="button"
                            className="shadow p-2 bg-white rounded-circle"
                            onClick={handleClose}
                            style={{ background: "#F5F5F5" }}
                          >
                            <img alt="ob-image" src="/assets/close.svg" />
                          </p>
                        </div>
                      </Row>
                    </Container>
                    <Container>
                      <form onSubmit={handleSubmit(onInfoForm)}>
                        <Row>

                          <div
                            className="col-6"
                          // style={{
                          //   padding: "10px 5px 10px 10px",

                          // }}
                          >

                            <input
                              type="text"
                              {...register("FirstName", {
                                required: true,
                              })}
                              className="common-input-box"
                              placeholder="First Name"
                            />
                            {errors.FirstName && (
                              <span
                                className="text-danger auth-validation-custom-span"
                              >
                                First Name is required
                              </span>
                            )}
                          </div>
                          <div
                            className="col-6"
                          // style={{
                          //   padding: "10px 0px 10px 25px",

                          // }}
                          >

                            <input
                              type="text"
                              {...register("LastName", {
                                required: true,
                              })}
                              className="common-input-box"
                              placeholder="Last Name"
                            />
                            {errors.LastName && (
                              <span
                                className="text-danger auth-validation-custom-span"
                              >
                                Last Name is required
                              </span>
                            )}
                          </div>

                          <div className="col-12">
                            <div className="d-flex common-input-box">
                              <div className="d-flex align-items-center px-2" style={{}}>
                                <p className="text-black d-flex align-items-center">
                                  <svg
                                    width={31}
                                    height={30}
                                    viewBox="0 0 31 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M15.0083 29.8114C23.2886 29.8114 30.0011 23.1539 30.0011 14.9414C30.0011 6.72885 23.2886 0.0712891 15.0083 0.0712891C6.72809 0.0712891 0.015625 6.72885 0.015625 14.9414C0.015625 23.1539 6.72809 29.8114 15.0083 29.8114Z"
                                      fill="#F0F0F0"
                                    />
                                    <path
                                      d="M8.55469 20.2178L9.86998 29.0672C13.6317 30.4476 17.792 30.2911 21.4374 28.6319C25.0828 26.9727 27.9154 23.9465 29.3132 20.2178H8.55469Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M8.55469 9.78162L9.86998 0.932157C13.6314 -0.447474 17.791 -0.290494 21.4359 1.36864C25.0807 3.02778 27.9129 6.05349 29.3107 9.78162H8.55469Z"
                                      fill="#6DA544"
                                    />
                                    <path
                                      d="M8.06025e-10 14.9987C-3.18685e-05 18.0616 0.944996 21.0512 2.70814 23.5658C4.47128 26.0805 6.96809 27.9997 9.86317 29.0658V0.932129C6.96817 1.99817 4.47142 3.91736 2.70829 6.4319C0.945148 8.94643 7.18711e-05 11.9359 8.06027e-10 14.9987H8.06025e-10Z"
                                      fill="#A2001D"
                                    />
                                  </svg>
                                  +971
                                </p>
                              </div>
                              <input
                                type="text"
                                onKeyDown={(e) => {
                                  const isCtrlKey = e.ctrlKey || e.metaKey; // Check if "ctrl" or "cmd" key is pressed
                                  const keyValue = e.key;
                                  const numericRegex = /^[0-9]*$/;
                                  if (
                                    !(
                                      numericRegex.test(keyValue) ||
                                      isCtrlKey ||
                                      keyValue === "Backspace"
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onPaste={(e) => {
                                  const pastedValue = e.clipboardData.getData("text");
                                  const numericRegex = /^[0-9]*$/;
                                  if (!numericRegex.test(pastedValue)) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value.length > e.target.maxLength)
                                    e.target.value = e.target.value.slice(
                                      0,
                                      e.target.maxLength
                                    );
                                }}
                                maxLength={9}
                                style={{
                                  border: "none",
                                  background: "transparent",
                                  width: "100%",
                                  fontSize: "14px",
                                }}
                                className=" px-1"
                                placeholder="Phone Number"
                                {...register("PhoneNumber", {
                                  required: true,
                                  validate: (value) => {
                                    return value[0] == "5"
                                      &&
                                      (value[1] === "5" ||
                                        value[1] === "2" ||
                                        value[1] === "0" ||
                                        value[1] === "6" ||
                                        value[1] === "4" ||
                                        value[1] === "8")
                                      && value.length === 9;
                                  },
                                })}
                              />
                            </div>
                            {errors.PhoneNumber && (
                              <span className="text-danger auth-validation-custom-span">
                                Enter valid phone number.
                              </span>
                            )}
                            {/* <div
                          className="col-12"
                            // style={{
                            //   padding: "0px 10px 10px 10px",
                             
                            // }}
                          >
                            <div>Mobile Number</div>
                            <div className="d-flex" >
                              <div
                                className="d-flex align-items-center px-2 "
                                style={{
                                  borderRight: "none",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px"
                                }}

                              >
                                <p className="text-black">+971</p>
                              </div>
                              <input
                                type="numeric"
                                className="common-input-box px-1"
                                onKeyDown={(e) => {
                                  const isCtrlKey = e.ctrlKey || e.metaKey; // Check if "ctrl" or "cmd" key is pressed
                                  const keyValue = e.key;
                                  const numericRegex = /^[0-9]*$/;
                                  if (!(numericRegex.test(keyValue) || isCtrlKey || keyValue === "Backspace")) {
                                    e.preventDefault();
                                  }
                                }}
                                onPaste={(e) => {
                                  const pastedValue = e.clipboardData.getData("text");
                                  const numericRegex = /^[0-9]*$/;
                                  if (!numericRegex.test(pastedValue)) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value.length > e.target.maxLength) {
                                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                                  }
                                }}
                                maxLength={9}
                                {...register("PhoneNumber", { required: true ,
                                  validate: (value) => value[0] === "5" && value.length === 9,
                                })}
                              />
                            </div>
                            {errors.PhoneNumber && (
                              <span className="text-danger">
                                Mobile Number is required
                              </span>
                            )}
                          </div> */}
                          </div>
                          <div
                            className="col-12"
                          >

                            <input
                              type="email"
                              {...register("Email", {
                                required: "Email is required",
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "Invalid email address",
                                },
                              })}
                              placeholder="Email"
                              className="common-input-box"
                            />
                            {errors.Email && (
                              <span

                                className="text-danger auth-validation-custom-span"
                              >
                                {errors.Email.message}
                              </span>
                            )}
                          </div>

                          <div
                            className="col-12"
                            style={{ margin: "10px 0px" }}
                          >
                            {apiData.emirates.length != 0 && (
                              <Controller
                                control={control}
                                name="EmirateId"
                                rules={{ required: true }}
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                  fieldState: {
                                    invalid,
                                    isTouched,
                                    isDirty,
                                    error,
                                  },
                                  formState,
                                }) => (
                                  <Select

                                    options={emirateList}
                                    placeholder="Select Emirate..."
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                    })}
                                    styles={{
                                      control: (styles) => ({
                                        ...styles,
                                        borderColor: "#ccc",
                                        borderRadius: 5,
                                        height: 45,
                                        fontSize: 14
                                      }),
                                    }}
                                  />
                                )}
                              />
                            )}

                            {errors.EmirateId && (
                              <span className="text-danger auth-validation-custom-span-em">
                                Emirate is required
                              </span>
                            )}
                          </div>
                        </Row>
                        <Row>
                          {!loginData.userAuth?.Id && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "10px 10px 20px 10px",
                              }}
                            >
                              <div
                                className="request-plan-txt"
                                style={{ color: "#F58B1F", fontSize: 13 }}
                              >
                                Note: OB will create accout for you
                              </div>
                              <div
                                className="request-plan-button"
                                style={{
                                  background: "#0451be",
                                  color: "#fff",
                                  height: "30px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                  padding: "5px",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  fontSize: 12,
                                }}
                                onClick={() => {
                                  toggleLoginForm(true);
                                  setLoginPopup(true);
                                  handleClose();
                                }}
                              >
                                Already a user? Login
                              </div>
                            </div>
                          )}
                        </Row>
                        <Row className="mt-3 justify-content-center" style={{ padding: "10px" }}>
                          <Button
                            type="submit"
                            variant=""
                            size="lg"
                            className="text-black btn-profile-update"
                            style={{
                              fontSize: "20px",
                              background: "linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%)",
                              border: "none",
                              height: "47px",
                              width: "90%",
                              fontWeight: "700",
                              borderRadius: "50px"
                            }}
                            disabled={isSubmitting}
                          >
                            Proceed
                          </Button>
                        </Row>
                      </form>
                    </Container>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getModel: (id) => {
      dispatch(getModel(id));
    },
    getEmirates: () => {
      dispatch(getEmirates());
    },
    fetchCars: () => {
      dispatch(fetchCars());
    },
    getCarBrandModal: (
      brand,
      modal,
      logoBrand,
      logoModal,
      brandId,
      modelId,
      slug = null
    ) => {
      dispatch(
        getCarBrandModal(
          brand,
          modal,
          logoBrand,
          logoModal,
          brandId,
          modelId,
          slug
        )
      );
    },
    setLoginPopup: (stage) => {
      dispatch(setLoginPopup(stage));
    },
    fetchCustomPlanList: () => {
      dispatch(fetchCustomPlanList());
    },
    toggleLoginForm: (stage) => {
      dispatch(toggleLoginForm(stage));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPlan);
