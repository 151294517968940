module.exports = {
  faq: [
    {
      id: 1,
      question: "What services does Open Bonnet provide? ",
      answer: "We are a one-stop-platform for all aftermarket (post car purchase) services a car owner may need. This includes periodic services, electrical repairs, mechanical repairs, collision repairs, tyres, batteries, spares, accessories, car wash, interior or exterior detailing, denting, painting, treatments, inspection. You can even request us for best quotes to buy car insurance or to rent a car.",
    },
    {
      id: 2,
      question: "Does Open Bonnet itself run the car garages whose services are listed on the platform?",
      answer: "Open Bonnet is a digital marketplace of verified service providers who are specialists for their offered jobs and work with Open Bonnet under a set of agreed service level offering that gives the best advantages to a car owner. We have already scanned the market for you and are ready with an assortment of quality workshops you always wanted for your car. We work in close coordination with our partner garages and so when your car is at the garage its our responsibility to ensure the best experience for you.",
    },
    {
      id: 3,
      question: "Does Open Bonnet markup or increase the workshop prices?",
      answer: "No. As a true marketplace, Open Bonnet assures Real Prices listed by Garages reach you directly. This is why for some of the services you will see a variety of price points across various service providers. We simply earn by charging the garages a small fee for connecting them to car owners. As we keep evolving our offering to give you best value, Open Bonnet also combines several services into what we call as Service Packages.",
    },
    {
      id: 4,
      question: "How do I request for a service with Open Bonnet workshop?",
      answer: "All jobs and service types are transparently detailed on the Open Bonnet website or on the dedicated workshop page and can be purchased or requested through the website itself. For any queries or in case of any confusion, our Service Advisors are most happy to receive your call or  <a href='https://api.whatsapp.com/send?phone=9718002274357' style='color:#47e247' target='_blank'>WhatsApp (8002274357)</a> with details for the service you are looking for.",
    },
    {
      id: 5,
      question: "I am in emergency, do I still have to go to Open Bonnet Website?",
      answer: "No. For any emergencies, our Service Advisors are most happy to receive your call <a href='tel:+9718002274357' style='color: #47e247;'>(8002274357)</a> or <a href='https://api.whatsapp.com/send?phone=9718002274357' target='_blank' style='color: #47e247;'>WhatsApp (8002274357)</a> with details for the service you are looking for. Our Service Advisor helpline is active from 8 AM to 8 PM, Mondays to Saturdays.",
    },
    {
      id: 6,
      question: "Does Open Bonnet give any assurance for the Service Quality?",
      answer: "Open Bonnet assures top service quality for your car while still keeping the prices at the Real Market levels. This has been made possible since all our partners have committed to offer special attention to our customers and are in close contact with our Service Advisor team. Additionally, you get 90 DAYS LABOR WARRANTY and GENUINE SPARES GUARANTEE for your job with Open Bonnet.",
    },
    {
      id: 7,
      question: "Do I need to make payment upfront for car service on Open Bonnet?",
      answer: "Not necessarily. On Open Bonnet platform you will find two types of jobs – Upfront Price Based or Quotation Based. The Upfront Price Based prices are where we have made your decision making easier by listing the exact job that will be done along with the amount you are expected to pay for it; typically jobs like oil change, brakepad change, car wash, detailing etc fall in this category. You can book these services by choosing to pay through your Credit/ Debit card or in Cash once the job is complete. While Quotation Based jobs are where we need to inspect your car before being able to give an exact price for the labor and spares to be used for your job; typically repair jobs fall in this category.",
    },
    {
      id: 8,
      question: "Why do I pay extra for Car Pick Up and Drop off service?",
      answer: "Open Bonnet provides a very convenient car pick up and drop option. We understand that some of our customers like visiting the workshop themselves and this is why we don’t add any pickup drop charges in our job prices by default – so you get best value for your money.  You can choose between a driver service or a flatbed service. You can request for the pickup drop facility on the checkout page before purchase or by talking to your Service Advisor. <br /> <br /> Comprehensive car insurance is mandatory to request chauffeur driven car pick-up drop service. In the unfortunate event of an accident you will be covered for the insurance access amount below or equal to AED 1,000 per incident.",
    },
    {
      id: 9,
      question: "Does Open Bonnet also provide any car services at my home or location?",
      answer: "Yes. A selected set of services like car wash, detailing, inspection, battery replacement/ inspection, lockout recovery etc are also optionally done by our home service providers active in selected Cities. We call this service as ‘Mobile Mechanic’ and you can simply go to the Mobile Mechanic service on our website to check if this is currently available in your city or not.",
    },
    {
      id: 10,
      question: "How does the OB Service Contract work?",
      answer: "The Open Bonnet car service & maintenance contracts provide complete care for your vehicle. You will get notifications to get the services done as bundled in the contract at regular intervals. Our experienced Service Advisors and Technicians will ensure thorough inspection of all major systems & engine performance to advise you the corrective measures at the right time. <br /> <br /> Service Packages cover oil change service & other selected services needed periodically. The price covers the labor & consumables (unless mentioned otherwise), required to perform the service. In case of any repairs or additional work found upon inspection of the vehicle, an optional estimate will be submitted for your approval.",
    },
    {
      id: 11,
      question: "What type of Spare Parts will be used for my car?",
      answer: "You are given option to choose between Original (OEM) spare parts or OES (Genuine Supplier) parts. OEM parts are the original parts produced by the manufacturer of your car while OES parts are the exact same part (perhaps without the 'Lexus' or 'Toyota' logo) sold by a supplier. OES parts are exactly the same and typically cost a little less. This way, its you who decide which part you want to get for your car. Our Service Advisors are of course always there to suggest you what can be your best option based on the part and the type of job.",
    },
    {
      id: 12,
      question: "What are Open Bonnet CashPoints?",
      answer: "OB CashPoints are our simple way to say Thanks for your trust in Open Bonnet services – they are loaded to your OB CashPoints Wallet every time you get your car job successfully done through Open Bonnet. The accumulated OB CashPoints are as good as cash and are available for use in your future jobs with Open Bonnet. What’s more – we keep running several promotions, which accelerate your Cash-Points accumulation.",
    },
    {
      id: 13,
      question: "How does the Buy-3-Get-1-Free offer on Oil Change work?",
      answer: "The Buy-3-Get-1-Free offer is an excellent way to get best value on the essential oil change service for your car. At an average our cars need an oil change every 10k KM or 3 months (this can vary based on type of oil used for the service). After you pay for 3 oil change services with Open Bonnet, you will automatically unlock the 4th FREE oil change offer redeemable from the same logged in account where the previous three qualifying jobs were purchased. The Buy-3-Get-1-Free offer is not valid in conjunction with any other offer.",
    },
    {
      id: 14,
      question: "How do I use Promo Code on Open Bonnet?",
      answer: "Open Bonnet Promo Codes are usable as per the terms and conditions mentioned on them. This can relate to use on website or their validity as per applicable job types, dates etc The promo code field can be found on the final checkout page that comes at the time of making payment for your job. In case you happen to call us first, you can even inform our Service Advisors that you will like to use the Promo Code.",
    },
    {
      id: 15,
      question: "What is the Open Bonnet Friend-Gets-Friend Offer?",
      answer: "We appreciate your support of the Open Bonnet platform and request you to spread the word around in case you like the OB Experience. When your friend/ contact creates an Open Bonnet account and uses your mobile number as the Referral Code, we will happily give both of you AED 25 worth of OB CashPoints that can be used on any service or repair with Open Bonnet! So go ahead and make the most of this offer.",
    },
    {
      id: 16,
      question: "Which Countries and Cities have Open Bonnet services active?",
      answer: "Currently Open Bonnet has a network of 70+ Garages across the following Emirates/ towns in UAE – Abu Dhabi, Dubai, Sharjah, Al Ain, Ajman and Ras Al Khaimah. Of course, our service network is rapidly increasing and we are reaching closer and closer to you each day!",
    }
  ],
};





































































